declare module 'o365.pwa.declaration.sw.utilities.JsonDecoderStream.d.ts' {
    export type T_JTOKEN_START_OBJECT = '{';
    export type T_JTOKEN_START_ARRAY = '[';

    export class JsonDecoderStream {
        constructor();

        public decodeChunk(value: Uint8Array, decodedItemCallback: (item: any, arrayIndex?: number) => void): boolean;
    }
}

export {}
